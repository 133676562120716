import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Typography, Divider, Button } from "@mui/material";
import { ReactComponent as ChevronRight } from "../../../assets/icons/svg/chevron-right.svg";
import { ReactComponent as TitleIcon } from "../../../assets/icons/svg/leaf-solid.svg";
import api from "../../../api/api";
import InAppPurchaseService from "../../../services/native/InAppPurchaseService.ts";
import { setAlert } from "../../../actions/alert";

const SubscriptionSettings = ({ auth: { plusProvider, isPlus }, device: { platform }, setAlert }) => {
  const [restoring, setRestoring] = useState(false);
  const handleManageSubscription = async () => {
    if (plusProvider === "apple") {
      window.location.href = "https://apps.apple.com/account/subscriptions";
    } else {
      try {
        const response = await api.post("/stripe/create-portal-session");
        window.location.href = response.data.url;
      } catch (error) {
        console.error("Error creating portal session:", error);
        // Handle error (e.g., show an error message to the user)
      }
    }
  };

  const handleRestoreSubscription = async () => {
    try {
      setRestoring(true);
      const restored = await InAppPurchaseService.restorePurchases();

      if (restored) {
        setAlert("thisPantry+ Subscription Restored!", "success");
      } else {
        setAlert("Failed to restore thisPantry+ Subscription. Please try again.", "error");
      }
    } catch (error) {
      console.error("Failed to restore purchases:", error);
      setAlert("Failed to restore thisPantry+ Subscription. Please try again.", "error");
    } finally {
      setRestoring(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>thisPantry - Subscription Settings</title>
      </Helmet>

      <div className="my-[4rem] md:my-0 md:pt-6 bg-neutral-50 min-h-screen">
        <div className="w-full md:pl-[5rem]">
          <div className="px-4 py-3 md:px-8 md:py-6 md:mx-auto w-full md:max-w-[600px] border-b md:border border-separator md:rounded-[16px] bg-background">
            <div className="flex flex-col">
              <div className="my-4 md:mt-0">
                <Link className="hover:bg-transparent text-secondaryText p-0 justify-start" to="/settings">
                  <Typography variant="body2" className="body1 leading-none">{`< Go Back`}</Typography>
                </Link>
              </div>
              <div className="flex flex-row mt-">
                <div className="mr-4">
                  <TitleIcon className="h-[1.25rem] fill-mainGreen"></TitleIcon>
                </div>
                <div className="mb-4">
                  <Typography variant="h6" className="font-normal leading-none mb-1">
                    Subscription Settings
                  </Typography>
                  <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                    Manage your thisPantry+ subscription
                  </Typography>
                </div>
              </div>
            </div>
            <Divider />
            <div className="flex flex-row justify-between items-center py-3">
              <div>
                <Typography variant="subtitle2" className="font-normal">
                  Manage Subscription
                </Typography>
                <Typography variant="body2" className="text-secondaryText">
                  Update payment method, cancel, or change plan
                </Typography>
              </div>
              <Button onClick={handleManageSubscription} variant="outlined" color="primary">
                Manage
              </Button>
            </div>
            <Divider />
            <Link to="/plus/">
              <div className="flex flex-row justify-between items-center py-3">
                <div>
                  <Typography variant="subtitle2" className="font-normal">
                    thisPantry+ Features
                  </Typography>
                  <Typography variant="body2" className="text-secondaryText">
                    View all thisPantry+ features
                  </Typography>
                </div>
                <div>
                  <ChevronRight className="h-[1rem] text-primaryText " />
                </div>
              </div>
            </Link>
            <Divider />
            <Link to="/plus/terms">
              <div className="flex flex-row justify-between items-center py-3">
                <div>
                  <Typography variant="subtitle2" className="font-normal">
                    Subscription Terms
                  </Typography>
                  <Typography variant="body2" className="text-secondaryText">
                    View subscription terms and conditions
                  </Typography>
                </div>
                <div>
                  <ChevronRight className="h-[1rem] text-primaryText " />
                </div>
              </div>
            </Link>
            {platform === "ios" && (
              <>
                <Divider />
                <Button
                  onClick={handleRestoreSubscription}
                  className="w-full p-0 pt-4 hover:bg-transparent"
                  disableRipple
                  disableElevation
                  disabled={restoring}
                >
                  <div className="w-full flex flex-row justify-between items-center">
                    <div className="w-full flex flex-col items-start text-start">
                      <Typography variant="subtitle2" className="leading-snug font-normal">
                        Restore Subscription
                      </Typography>
                      <Typography variant="body2" className="text-secondaryText leading-snug mt-1">
                        Synchronize your subscription if you have purchased one with your Apple ID
                      </Typography>
                    </div>
                    <div className="ml-4">
                      <ChevronRight className="h-[1rem] text-primaryText " />
                    </div>
                  </div>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

SubscriptionSettings.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  device: state.device,
});

export default connect(mapStateToProps, { setAlert })(SubscriptionSettings);
