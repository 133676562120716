import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link, useLocation } from "react-router-dom";

//MUI
import { Slide, Badge, IconButton } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import { ReactComponent as LeafLogo } from "../../../assets/logos/icon.svg";

//Bottom icons
import { ReactComponent as HomeIcon } from "../../../assets/icons/svg/home.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/svg/search.svg";
import { ReactComponent as CreateIcon } from "../../../assets/icons/svg/create.svg";
import { ReactComponent as KitchenIcon } from "../../../assets/icons/svg/kitchen-dashboard.svg";
import { ReactComponent as NotificationsIcon } from "../../../assets/icons/svg/heart.svg";

//Default PFP
import { ReactComponent as DefaultPFP } from "../../../assets/default/profile_picture.svg";
import { getUnreadNotifications } from "../../../actions/notifications";

//Actions
import { toggleCreateModal, toggleKitchenDashboardModal } from "../../../actions/navigation";

//Modals
import CreateOptionsModal from "../modals/CreateOptionsModal";
import KitchenDashboardModal from "../modals/KitchenDashboardModal";

//Capacitor
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";

//Debouncing function for scroll position
const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const Mobile = ({
  profile: { currentProfile },
  auth: { user },
  notifications: { unread },
  getUnreadNotifications,
  toggleCreateModal,
  toggleKitchenDashboardModal,
}) => {
  const location = useLocation();
  const [bottomOpacity, setBottomOpacity] = useState(1);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleScroll = debounce(() => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollTop) {
      setBottomOpacity(0.4);
    } else {
      setBottomOpacity(1);
    }

    setLastScrollTop(currentScrollY);
  }, 100); // Debounce time in milliseconds, adjust as needed

  const [capacitorPlatform, setCapacitorPlatform] = useState(null);

  const getCapacitorPlatform = () => {
    const platform = Capacitor.getPlatform();

    if (platform) {
      setCapacitorPlatform(platform);
      return platform;
    } else {
      setCapacitorPlatform("web");
      return "web";
    }
  };

  useEffect(() => {
    getCapacitorPlatform();
  }, []);
  //Set status bar for dark bg, reset on return
  const setStatusBar = async (styleType) => {
    const platform = getCapacitorPlatform();
    if (platform === "ios") {
      if (styleType === "light") {
        await StatusBar.setStyle({ style: Style.Light });
      } else if (styleType === "dark") {
        await StatusBar.setStyle({ style: Style.Dark });
      } else {
        return null;
      }
    }
  };

  useEffect(() => {
    setStatusBar("light");
  }, []);

  const handleCreateOptionsModalClose = () => {
    toggleCreateModal(false);
  };

  const handleKitchenDashboardModalClose = () => {
    toggleKitchenDashboardModal(false);
  };

  const onNavigation = () => {
    window.scrollTo(0, 0);
    setBottomOpacity(1);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    getUnreadNotifications();
  }, [location]);
  // For top navigation bar
  const trigger = useScrollTrigger();

  const isWalkthrough = location.pathname.match(/\/cookbook\/[a-f\d]{24}$/);

  /*Walkthrough is a special case, we don't want to show the top navigation bar, there is a separate one for the walkthrough
  if (isWalkthrough) {
    return null;
  }*/

  return (
    <div>
      <CreateOptionsModal onClose={handleCreateOptionsModalClose} />
      <KitchenDashboardModal onClose={handleKitchenDashboardModalClose} />

      {!isWalkthrough && (
        <Slide in={!trigger} direction="down">
          <div className="min-h-[4rem] border-b border-separator bg-background fixed top-0 w-full flex items-center justify-between px-4 pt-[calc(0.75rem+env(safe-area-inset-top))] z-[1200] pb-4">
            <Link to="/" onClick={() => onNavigation()} className="flex items-center">
              <LeafLogo className="fill-mainGreen h-[2.25rem]" />
            </Link>
            <div>
              <Link to="/notifications" onClick={() => onNavigation()}>
                <Badge badgeContent={unread} color="primary" max={99}>
                  <NotificationsIcon className="fill-primaryText h-[1.5rem]" />
                </Badge>
              </Link>
            </div>
          </div>
        </Slide>
      )}

      <div
        className="min-h-[4rem] border-t border-separator bg-background fixed bottom-0 w-full flex items-start z-[1200] px-4 transition-opacity duration-300 pb-[calc(0+env(safe-area-inset-bottom))]"
        style={{ opacity: bottomOpacity }}
      >
        <div className="flex justify-between pt-4 w-full px-4">
          <div>
            <Link to="/" onClick={() => onNavigation()}>
              <IconButton className="p-0 hover:bg-transparent" disableRipple>
                <HomeIcon className="fill-primaryText h-[1.5rem]" />
              </IconButton>
            </Link>
          </div>
          <div>
            <Link to="/search" onClick={() => onNavigation()}>
              <IconButton className="p-0 hover:bg-transparent" disableRipple>
                <SearchIcon className="fill-primaryText h-[1.5rem]" />
              </IconButton>
            </Link>
          </div>
          <div>
            <IconButton onClick={() => toggleCreateModal(true)} className="p-0 hover:bg-transparent" disableRipple>
              <CreateIcon className="fill-primaryText h-[1.5rem]" />
            </IconButton>
          </div>
          {capacitorPlatform === "ios" && !(user && user.isAdmin) ? (
            <Link to="/cookbook">
              <IconButton className="p-0 hover:bg-transparent" disableRipple>
                <KitchenIcon className="fill-primaryText h-[1.5rem]" />
              </IconButton>
            </Link>
          ) : (
            <div>
              <IconButton
                onClick={() => toggleKitchenDashboardModal(true)}
                className="p-0 hover:bg-transparent"
                disableRipple
              >
                <KitchenIcon className="fill-primaryText h-[1.5rem]" />
              </IconButton>
            </div>
          )}

          <div>
            {currentProfile.picture === "" ? (
              <Link to={`/${currentProfile.username}`} onClick={() => onNavigation()}>
                <IconButton className="p-0 hover:bg-transparent" disableRipple>
                  <DefaultPFP className="h-[1.75rem] w-[1.75rem] object-cover rounded-[50%] border border-primaryText" />
                </IconButton>
              </Link>
            ) : (
              <Link to={`/${currentProfile.username}`} onClick={() => onNavigation()}>
                <IconButton className="p-0 hover:bg-transparent" disableRipple>
                  <img
                    src={currentProfile.picture}
                    alt="Profile"
                    className="h-[1.75rem] w-[1.75rem] object-cover rounded-[50%] border border-primaryText"
                  />
                </IconButton>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Mobile.propTypes = {
  profile: PropTypes.object.isRequired,
  notifications: PropTypes.object.isRequired,
  getUnreadNotifications: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  notifications: state.notifications,
  auth: state.auth,
});

export default connect(mapStateToProps, { getUnreadNotifications, toggleCreateModal, toggleKitchenDashboardModal })(
  Mobile
);
