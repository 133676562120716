import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Actions
import { hideRegisterModal } from "../../../actions/auth";
import { setSignupStage, preferencesNext } from "../../../actions/register";

//MUI Components
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
//Autocomplete Data

import { initializeNotifications } from "../../../utils/notifications";

const Preferences = ({ hideRegisterModal, setSignupStage, preferencesNext }) => {
  //State

  const handleNext = async () => {
    await initializeNotifications();
    preferencesNext({ notifications: true });
  };

  const handleSkip = () => {
    preferencesNext({ notifications: false });
  };

  //TO DO:
  //Display errors
  //Verify inputs on frontend, check username before moving to 2nd stage
  //Create account via action + get and store token
  //Signup progress bar?
  return (
    <div className="w-full flex items-center justify-center flex-col mb-[4rem] md:mb-0">
      <div className="px-4 md:px-8 py-8 w-full">
        <div className="my-4 md:mb-8 w-full flex flex-col">
          <Typography variant="h4" className="text-mainGreen font-bold">
            Stay Fresh!
          </Typography>
          <Typography variant="body2" className="mt-2">
            Get notified when your ingredients are about to expire, new recipe suggestions, social updates, and more.
          </Typography>
        </div>
        <div className="w-full">
          <Button
            fullWidth
            disableElevation
            variant="contained"
            size="large"
            className="mb-2 bg-mainGreen text-background"
            onClick={() => handleNext()}
          >
            Get Notified
          </Button>
          <Button
            fullWidth
            disableElevation
            variant="outlined"
            size="large"
            onClick={() => handleSkip()}
            className="border-secondaryText-300 hover:border-secondaryText-600 hover:bg-secondaryText/10"
          >
            <Typography variant="body1" className="text-secondaryText">
              Not Now
            </Typography>
          </Button>
          <div className="text-center mt-1">
            <Typography variant="caption" className="text-secondaryText">
              You can control your notification preferences at any time in your settings.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

Preferences.propTypes = {
  hideRegisterModal: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
  preferencesNext: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { hideRegisterModal, setSignupStage, preferencesNext })(Preferences);
