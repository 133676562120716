import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Actions
import { login, hideLoginModal, toggleLoginToRegisterModal } from "../../../actions/auth";
import { setSignupStage } from "../../../actions/register";
//MUI Styling
import { useMediaQuery } from "@mui/material";

//MUI Components
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

//Logo
import { ReactComponent as Logo } from "../../../assets/logos/full_logo.svg";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as GoogleIcon } from "../res/google.svg";
import { ReactComponent as AppleIcon } from "../../../assets/icons/svg/apple-brands-solid.svg";

//Capacitor
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

const LoginContent = ({
  login,
  setSignupStage,
  hideLoginModal,
  auth: {
    errors: { loginErrors },
  },
  modal = false,
  toggleLoginToRegisterModal,
}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [password, setPassword] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [showPasswords, setShowPasswords] = useState(false);
  const [capacitorPlatform, setCapacitorPlatform] = useState(null);

  const getCapacitorPlatform = () => {
    const platform = Capacitor.getPlatform();

    console.log(platform);

    if (platform) {
      console.log(platform);
      setCapacitorPlatform(platform);
    } else {
      console.log("No platform");
      setCapacitorPlatform("web");
    }
  };
  useEffect(() => {
    getCapacitorPlatform();
  }, []);

  const handleGoogleClick = async () => {
    if (capacitorPlatform === "web") {
      await Browser.open({ url: "https://www.thispantry.com/api/auth/google" });
    } else if (capacitorPlatform === "ios") {
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/google/ios",
      });
    } else if (capacitorPlatform === "android") {
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/google",
        presentationStyle: "popover",
        windowName: "_self",
        toolbarColor: "#598a56",
      });
    }
  };

  const handleAppleClick = async () => {
    if (capacitorPlatform === "web") {
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/apple",
      });
    } else if (capacitorPlatform === "ios") {
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/apple/ios",
      });
    } else if (capacitorPlatform === "android") {
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/apple",
        presentationStyle: "popover",
        windowName: "_self",
        toolbarColor: "#598a56",
      });
    }
  };
  const handlePassword = (e) => {
    if (e.target.value.length > 64) {
      return;
    }
    setPassword(e.target.value);
  };

  const handleIdentifier = (e) => {
    setIdentifier(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    login({ identifier, password });
  };

  return (
    <div className="w-full flex flex-col text-center items-center justify-center">
      <Logo className="h-[4rem] md:h-[5rem] select-none fill-mainGreen mb-8" />
      <form onSubmit={(e) => handleSubmit(e)} className="w-full">
        <div className="w-full flex flex-col gap-4">
          <TextField
            fullWidth
            label="Username/Email"
            type="text"
            variant="outlined"
            value={identifier}
            onChange={handleIdentifier}
            size={isSmallScreen ? "small" : "medium"}
            error={loginErrors && loginErrors.username && !loginErrors.username.isValid}
            helperText={
              loginErrors && loginErrors.username && !loginErrors.username.isValid ? loginErrors.username.error : null
            }
          />
          <TextField
            fullWidth
            label="Password"
            type={showPasswords ? "text" : "password"}
            variant="outlined"
            value={password}
            onChange={handlePassword}
            size={isSmallScreen ? "small" : "medium"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPasswords(!showPasswords)}
                    edge="end"
                    size="small"
                    tabIndex={-1}
                  >
                    <FontAwesomeIcon icon={showPasswords ? "eye-slash" : "eye"} className="text-secondaryText/60" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={loginErrors && loginErrors.password && !loginErrors.password.isValid}
            helperText={
              loginErrors && loginErrors.password && !loginErrors.password.isValid ? loginErrors.password.error : null
            }
          />
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disableElevation
            style={{ textTransform: "none" }}
            type="submit"
          >
            Log in
          </Button>
          <div className="w-full h-full flex items-center content-center flex-row justify-between my-2">
            <div className="h-[1px] bg-separator w-[35%]"></div>
            <div>
              <Typography variant="subtitle1">or</Typography>
            </div>
            <div className="h-[1px] bg-separator w-[35%]"></div>
          </div>
          <div className="flex flex-col gap-2">
            <Button
              variant="outlined"
              fullWidth
              startIcon={<GoogleIcon className="h-[1.5rem]" />}
              classes={{
                root: "relative pl-[1.5rem] border-secondaryText-300 hover:border-secondaryText",
                startIcon: "absolute left-[1rem]",
              }}
              size={isSmallScreen ? "medium" : "large"}
              onClick={handleGoogleClick}
            >
              Log in with Google
            </Button>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<AppleIcon className="h-[1.5rem] fill-black" />}
              classes={{
                root: "relative pl-[1.5rem] border-secondaryText-300 hover:border-secondaryText",
                startIcon: "absolute left-[1rem]",
              }}
              size={isSmallScreen ? "medium" : "large"}
              onClick={handleAppleClick}
            >
              Log in with Apple
            </Button>
          </div>

          <Typography variant="subtitle2" className="mt-2 leading-none py-1">
            Don't have an account?{" "}
            {modal ? (
              <Button
                className="px-2 py-0 max-w-fit hover:bg-transparent text-mainGreen font-medium"
                disableRipple
                onClick={() => toggleLoginToRegisterModal()}
              >
                Sign Up
              </Button>
            ) : (
              <Link to="/signup" className="text-mainGreen" onClick={() => hideLoginModal()}>
                Sign Up
              </Link>
            )}
          </Typography>
        </div>
      </form>
    </div>
  );
};

LoginContent.propTypes = {
  login: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
  hideLoginModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  toggleLoginToRegisterModal: PropTypes.func.isRequired,
  modal: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  login,
  setSignupStage,
  hideLoginModal,
  toggleLoginToRegisterModal,
})(LoginContent);
