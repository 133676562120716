import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Spinner from "../layout/Spinner";

const PrivateRoute = ({ element: Element, requireSignupComplete = true,  ...rest }) => {
  const { isAuthenticated, loading, user } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  if (loading) {
    return (
      <div className="w-full min-h-[calc(100vh-[4rem])] md:min-h-screen flex items-center justify-center">
        <Spinner />
      </div>
    )
  }

  // Check if user is authenticated
  if (!isAuthenticated && !loading) {
    navigate('/login');
  }

  // Check if signup needs to be completed
  if (requireSignupComplete && !loading && isAuthenticated && user && user?.signupStage && user.signupStage !== null) {
    navigate(`/welcome/${user.signupStage}`);
  }

  return <Element {...rest} />;
};

PrivateRoute.propTypes = {
  element: PropTypes.elementType.isRequired,
  requireSignupComplete: PropTypes.bool,
};

export default PrivateRoute;
