import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
//Actions
import { hideRegisterModal } from "../../actions/auth";
import { setSignupStage } from "../../actions/register";

//Sections
import Initial from "./register/Initial";
import BasicInfo from "./register/BasicInfo";

//Icons
import { ReactComponent as BackIcon } from "../../assets/icons/svg/chevron-left.svg";
import { ReactComponent as ExitIcon } from "../../assets/icons/svg/exit.svg";

//Config
import { SIGNUP_STAGES, getPreviousStage } from "./register/config/RegisterConfig";

const RegisterModal = ({
  register: { stage },
  auth: { modals, isAuthenticated },
  hideRegisterModal,
  setSignupStage,
}) => {
  const onRegisterModalClose = () => {
    setSignupStage(SIGNUP_STAGES.INITIAL);
    hideRegisterModal();
  };

  //Navigation per stage

  if (stage) {
    return (
      <Modal
        aria-labelledby="Sign up"
        aria-describedby="Sign up to thisPantry"
        open={modals.register}
        onClose={onRegisterModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modals.register}>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Paper
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mx-auto outline-none"
              elevation={0}
            >
              <div className="bg-backround rounded-lg min-h-[10vh] max-h-[85vh] w-[95vw] md:min-w-[600px] overflow-y-auto max-w-[450px] md:max-w-[30vw] shadow-sm ">
                {stage === SIGNUP_STAGES.BASIC_INFO && (
                  <div className="w-full flex items-center justify-between flex-row top-0 p-4 fixed">
                    <Button
                      aria-label="back"
                      startIcon={<BackIcon className="h-[1rem]" />}
                      onClick={() => setSignupStage(SIGNUP_STAGES.INITIAL)}
                      className="text-primaryText hover:bg-transparent"
                      disableRipple
                    >
                      <Typography variant="subtitle2" className="font-normal">
                        Back
                      </Typography>
                    </Button>

                    <IconButton
                      className="p-0 hover:bg-transparent w-[1rem]"
                      disableRipple
                      onClick={() => onRegisterModalClose()}
                    >
                      <ExitIcon className="h-[1rem]" />
                    </IconButton>
                  </div>
                )}

                {stage === SIGNUP_STAGES.INITIAL && (
                  <div className="px-4 sm:px-8 md:px-12 py-8 md:py-16">
                    <Initial modal={true} />
                  </div>
                )}
                {stage === SIGNUP_STAGES.BASIC_INFO && (
                  <div className="px-4 md:px-8  py-8 md:py-16">
                    <BasicInfo />
                  </div>
                )}
              </div>
            </Paper>
          </div>
        </Fade>
      </Modal>
    );
  } else {
    return null;
  }
};

RegisterModal.propTypes = {
  register: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  hideRegisterModal: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  register: state.register,
  auth: state.auth,
});

export default connect(mapStateToProps, { hideRegisterModal, setSignupStage })(RegisterModal);
