import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import axios from "axios";

import Spinner from "../../layout/Spinner";

import { setTokensFromCallback } from "../../../actions/auth";

import { useParams, useNavigate } from "react-router-dom";

import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { AppLauncher } from "@capacitor/app-launcher";

const OAuthFetchUser = ({ setTokensFromCallback }) => {
  const { token, type } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.post("https://www.thispantry.com/api/auth/oauth", { token, type });
        console.log("OAuth response:", res.data);
        await setTokensFromCallback(res.data.token, res.data.refreshToken);
      } catch (error) {
        console.error("Error in OAuth callback:", error);
        navigate("/login");
      }
    };

    fetchUser();
  }, [token, type, setTokensFromCallback]);

  return (
    <div className="w-full h-full m-auto">
      <Spinner />
    </div>
  );
};

OAuthFetchUser.propTypes = {
  setTokensFromCallback: PropTypes.func.isRequired,
};

export default connect(null, { setTokensFromCallback })(OAuthFetchUser);
