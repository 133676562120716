//Create a MUI v4 modal template that matches the style of the LoginModal
import React, { useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import { ReactComponent as ExitIcon } from "../../assets/icons/svg/exit.svg";

//TO DO:
//Types -> centered, bottom (at right edge for desktop?)
//Framer appear animations (for centered or bottom)

//Account for desktop nav on centered (set as true/false to do so?)

export default function CustomModal(props) {
  const {
    open,
    handleClose,
    children,
    showExit = true,
    contentStyleOverride = {},
    contentClassNameOverride = "",
    parentClassNameOverride = "",
    type = "centered",
    disableScrollLock = false,
    disableBodyScroll = true,
    border = true,
  } = props; //destructure props

  //TODO:Stop background scrolling when modal is open
  const contentRef = useRef(null);

  //Pass scrollToTop functionality to child components
  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { scrollToTop: scrollToTop });
    }
    return child;
  });

  const centeredVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.9 },
  };

  const bottomVariants = {
    hidden: { opacity: 0, y: "100%", x: "-50%" },
    visible: { opacity: 1, y: "0%", x: "-50%" },
    exit: { opacity: 0, y: "100%", x: "-50%" },
  };

  if (type === "centered") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          disableScrollLock={disableScrollLock}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <motion.div
            initial="hidden"
            animate={open ? "visible" : "hidden"}
            exit="exit"
            variants={centeredVariants}
            transition={{ duration: 0.125 }}
            className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:ml-[2.5rem] ${parentClassNameOverride}`}
          >
            <Paper
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 outline-none mx-auto"
              elevation={0}
            >
              <div
                className={`w-full bg-background rounded-lg ${border ? "border border-solid border-separator" : ""}`}
              >
                {showExit && (
                  <div className="w-full h-fit flex items-center justify-between flex-row top-0 py-2 fixed px-4 mb-2 ">
                    <div></div>
                    <IconButton
                      aria-label="exit"
                      onClick={() => handleClose()}
                      className="text-none hover:bg-transparent px-0 py-1"
                      size="large"
                      disableRipple
                    >
                      <ExitIcon className="h-[1rem] fill-primaryText" />
                    </IconButton>
                  </div>
                )}

                <div
                  className={`w-[90vw] min-w-0 min-h-[10vh] max-h-[85vh] overflow-y-auto md:w-[70vw] md:max-w-[600px] ${
                    showExit ? "mt-9 " : "mt-0 "
                  }
                     ${contentClassNameOverride}`}
                  style={contentStyleOverride}
                  ref={contentRef}
                >
                  {childrenWithProps}
                </div>
              </div>
            </Paper>
          </motion.div>
        </Modal>
      </div>
    );
  } else if (type === "bottom") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          disableScrollLock={disableScrollLock}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <motion.div
            initial="hidden"
            animate={open ? "visible" : "hidden"}
            exit="exit"
            variants={bottomVariants}
            transition={{ duration: 0.175 }}
            className={`fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-[100vw] md:max-w-[80vw] lg:max-w-[60vw] xl:max-w-[50vw] md:ml-[2.5rem] ${parentClassNameOverride}`}
          >
            <Paper className="outline-none mx-auto w-full " elevation={0}>
              <div
                className={`w-full bg-background rounded-t-lg ${border ? "border border-solid border-separator" : ""}`}
              >
                {showExit && (
                  <div className="w-full h-fit flex items-center justify-between flex-row top-0 py-2 fixed px-4 mb-2 bg-background rounded-t-lg">
                    <div></div>
                    <IconButton
                      aria-label="exit"
                      onClick={() => handleClose()}
                      className="text-none hover:bg-transparent px-0"
                      size="large"
                      disableRipple
                    >
                      <ExitIcon className="h-[1rem] fill-primaryText" />
                    </IconButton>
                  </div>
                )}

                <div
                  className={`w-full min-w-0 min-h-[10vh] max-h-[95vh] overflow-y-auto ${
                    showExit ? "mt-12" : "mt-0"
                  } ${contentClassNameOverride}`}
                  style={contentStyleOverride}
                  ref={contentRef}
                >
                  {childrenWithProps}
                </div>
              </div>
            </Paper>
          </motion.div>
        </Modal>
      </div>
    );
  }
}

CustomModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.node,
  showExit: PropTypes.bool,
  contentStyleOverride: PropTypes.object,
  contentClassNameOverride: PropTypes.string,
  type: PropTypes.oneOf(["centered", "bottom"]),
  disableScrollLock: PropTypes.bool,
  disableBodyScroll: PropTypes.bool,
};
