import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { oauthInfoNext } from "../../../actions/register";
import { Link } from "react-router-dom";

import api from "../../../api/api";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { TextField, Button, Typography } from "@mui/material";

//MUI styling
import { useMediaQuery } from "@mui/material";

import { SIGNUP_STAGES } from "./config/RegisterConfig";

const OAuthInfo = ({ auth: { user, loading }, oauthInfoNext, register: { errors } }) => {
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [dob, setDOB] = useState(dayjs());
  //Breakpoints
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleSubmit = () => {
    oauthInfoNext({ username, name, dob });
  };

  const handleUsername = (e) => {
    if (e.target.value?.length > 20) {
      return;
    }
    setUsername(e.target.value);
  };

  const handleName = (e) => {
    if (e.target.value?.length > 100) {
      return;
    }
    setName(e.target.value);
  };

  useEffect(() => {
    setUsername(user.username);
    setName(user.name);
    setDOB(user?.stats?.dob ? dayjs(user?.stats?.dob) : dayjs());
  }, [loading]);

  return (
    <div className="w-full flex items-center justify-center flex-col mb-[4rem] md:mb-0">
      <div className="px-4 md:px-8 py-8 w-full">
        <div className="my-4 md:mb-8 w-full flex flex-col">
          <Typography variant="h4" className="text-mainGreen font-bold">
            Let's get cookin'<br></br>
          </Typography>

          <Typography variant="subtitle2" className="font-normal">
            Get started with some basic info
          </Typography>
        </div>

        <div className="w-full flex flex-col gap-4 mb-2 md:mb-4">
          <TextField
            fullWidth
            label="Username"
            variant="outlined"
            value={username}
            onChange={handleUsername}
            inputProps={{ maxLength: 20 }}
            error={errors && !errors[SIGNUP_STAGES.OAUTH_INFO].username.isValid}
            helperText={
              errors && !errors[SIGNUP_STAGES.OAUTH_INFO].username.isValid
                ? errors[SIGNUP_STAGES.OAUTH_INFO].username.error
                : ""
            }
          />
          <TextField
            fullWidth
            label="Name"
            variant="outlined"
            value={name}
            onChange={handleName}
            inputProps={{ maxLength: 100 }}
            error={errors && !errors[SIGNUP_STAGES.OAUTH_INFO].name.isValid}
            helperText={
              errors && !errors[SIGNUP_STAGES.OAUTH_INFO].name.isValid
                ? errors[SIGNUP_STAGES.OAUTH_INFO].name.error
                : ""
            }
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Birthday"
              value={dob}
              onChange={(newDate) => setDOB(newDate)}
              sx={{ backgroundColor: "white" }}
              slotProps={{
                textField: {
                  error: !errors[SIGNUP_STAGES.OAUTH_INFO].dob.isValid,
                  helperText: !errors[SIGNUP_STAGES.OAUTH_INFO].dob.isValid
                    ? errors[SIGNUP_STAGES.OAUTH_INFO].dob.error
                    : "",
                },
              }}
              renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
              inputFormat="MM/DD/YYYY"
            />
          </LocalizationProvider>
          <Typography variant="caption" className="text-secondaryText">
            You must be at least 13 years old to use thisPantry.<br></br>
            If you are under 13, you can delete your account{" "}
            <Link to="/settings/delete" className="text-mainGreen font-medium">
              here.
            </Link>
          </Typography>
        </div>
        <Button
          fullWidth
          disableElevation
          variant="contained"
          size={isSmallScreen ? "medium" : "large"}
          className="bg-mainGreen text-background"
          onClick={() => handleSubmit()}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  register: state.register,
});

export default connect(mapStateToProps, { oauthInfoNext })(OAuthInfo);
