import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Actions
import { hideRegisterModal } from "../../../actions/auth";
import { setSignupStage, abilityNext } from "../../../actions/register";

//MUI Components
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
//MUI styling
import { useMediaQuery } from "@mui/material";

const Ability = ({ hideRegisterModal, setSignupStage, abilityNext }) => {
  const [selection, setSelection] = useState(-1);

  const handleSelection = (value) => {
    //Uncheck
    if (value === selection) {
      setSelection(-1);
      return;
    }

    setSelection(value);
  };

  //TO DO:
  //Add allergies data + refine special diets data
  //Log data in state (on next)
  //Create backend route to store info
  //Move to next section
  return (
    <div className="w-full flex items-center justify-center flex-col mb-[4rem] md:mb-0">
      <div className="px-4 md:px-8 py-8 w-full">
        <div className="my-4 md:mb-8 w-full flex flex-col">
          <Typography variant="h4" className="text-mainGreen font-bold">
            Cooking Ability
          </Typography>

          <Typography variant="subtitle2" className="font-normal">
            What do you agree with most? 1-5
          </Typography>
        </div>
        <div className="w-full flex flex-col mb-2 md:mb-4">
          <Button
            variant="outlined"
            className="min-h-[4rem] md:min-h-[5rem] px-2 md:px-4"
            onClick={() => handleSelection(5)}
          >
            <Checkbox color="primary" checked={selection === 5} onChange={() => handleSelection(5)}></Checkbox>
            <Typography className="ml-2 md:ml-4 w-full text-left" variant="body2">
              5 - I enjoy creating and experimenting with recipes
            </Typography>
          </Button>
        </div>
        <div className="w-full flex flex-col mb-2 md:mb-4">
          <Button
            variant="outlined"
            className="min-h-[4rem] md:min-h-[5rem] px-2 md:px-4"
            onClick={() => handleSelection(4)}
          >
            <Checkbox color="primary" checked={selection === 4} onChange={() => handleSelection(4)}></Checkbox>
            <Typography className="ml-2 md:ml-4 w-full text-left" variant="body2">
              4 - I'm pretty confident finding my way around a kitchen
            </Typography>
          </Button>
        </div>
        <div className="w-full flex flex-col mb-2 md:mb-4">
          <Button
            variant="outlined"
            className="min-h-[4rem] md:min-h-[5rem] px-2 md:px-4"
            onClick={() => handleSelection(3)}
          >
            <Checkbox color="primary" checked={selection === 3} onChange={() => handleSelection(3)}></Checkbox>
            <Typography className="ml-2 md:ml-4 w-full text-left" variant="body2">
              3 - I've tackled a few recipes from cookbooks
            </Typography>
          </Button>
        </div>
        <div className="w-full flex flex-col mb-2 md:mb-4">
          <Button
            variant="outlined"
            className="min-h-[4rem] md:min-h-[5rem] px-2 md:px-4"
            onClick={() => handleSelection(2)}
          >
            <Checkbox color="primary" checked={selection === 2} onChange={() => handleSelection(2)}></Checkbox>
            <Typography className="ml-2 md:ml-4 w-full text-left" variant="body2">
              2 - I can whip up a mean grilled cheese sandwich
            </Typography>
          </Button>
        </div>
        <div className="w-full flex flex-col mb-2 md:mb-4">
          <Button
            variant="outlined"
            className="min-h-[4rem] md:min-h-[5rem] px-2 md:px-4"
            onClick={() => handleSelection(1)}
          >
            <Checkbox color="primary" checked={selection === 1} onChange={() => handleSelection(1)}></Checkbox>
            <Typography className="ml-2 md:ml-4 w-full text-left" variant="body2">
              1 - I can burn a pot of water without even trying
            </Typography>
          </Button>
        </div>
        <Button
          fullWidth
          disableElevation
          variant="contained"
          size="large"
          className="my-4 bg-mainGreen text-background"
          onClick={() => abilityNext({ ability: selection })}
          disabled={selection === -1}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

Ability.propTypes = {
  hideRegisterModal: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
  abilityNext: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { hideRegisterModal, setSignupStage, abilityNext })(Ability);
